var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-v flex-align-center w100 ignore-case"},[_vm._m(0),(_vm.showList)?_c('div',{staticClass:"ignore-case-list"},[_vm._l((_vm.caseList),function(item,index){return _c('div',{key:index,staticClass:"ignore-case-item flex flex-align-center"},[_c('router-link',{attrs:{"to":{
          name: 'caseCenter',
          query: { caseId: item.articleId, name: item.articleTitle },
        },"target":"_blank"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.coverUrl + item.coverPath + item.articleCover),expression:"item.coverUrl + item.coverPath + item.articleCover"}],staticClass:"ignore-case-icon"})]),_c('div',{staticClass:"flex-v flex-1 h100"},[_c('router-link',{attrs:{"to":{
            name: 'caseCenter',
            query: { caseId: item.articleId, name: item.articleTitle },
          },"target":"_blank"}},[_c('div',{staticClass:"ignore-fz24 bold ignore-mt20"},[_vm._v(" "+_vm._s(item.articleTitle)+" ")])]),_c('div',{staticClass:"ignore-fz14 ignore-mt30 max-line3 flex ignore-ml3-fz14"},[_vm._v(" "+_vm._s(item.articleIntro)+" ")]),_c('div',{staticClass:"flex flex-1"}),_c('div',{staticClass:"flex w100 flex-align-center flex-pack-between"},[_c('div',{staticClass:"flex flex-align-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.watchPic),expression:"watchPic"}],staticClass:"ignore-watch-icon"}),_c('span',{staticClass:"ignore-fz14 c-999"},[_vm._v(_vm._s(item.pv))])]),_c('div',{staticClass:"relative ignore-more-btn-box",on:{"mouseenter":function($event){item.active = true},"mouseleave":function($event){item.active = false}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.active !== true),expression:"item.active !== true"}],staticClass:"animate__animated ignore-more-btn flex flex-align-center flex-pack-center c-39e ignore-fz16 pointer"},[_vm._v(" 更多 ")]),_c('router-link',{attrs:{"to":{
                name: 'caseCenter',
                query: { caseId: item.articleId, name: item.articleTitle  },
              },"target":"_blank"}},[_c('div',{staticClass:"animate__animated ignore-more-btn-active flex flex-align-center flex-pack-center c-white ignore-fz16 pointer",class:item.active == true
                    ? 'animate__fadeIn'
                    : item.active == false
                    ? 'animate__fadeOut'
                    : 'more-hidden'},[_vm._v(" 更多 ")])])],1)])],1)],1)}),(_vm.totalRecord > 8)?_c('el-pagination',{staticClass:"ignore-mt20 pagination",attrs:{"background":"white","layout":"prev, pager, next","total":_vm.totalRecord,"current-page":_vm.pageIndex},on:{"current-change":_vm.handleCurrentChange}}):_vm._e()],2):_c('div',{staticClass:"ignore-case-detail"},[_c('div',{staticClass:"ignore-fz14 c-39e pointer",on:{"click":_vm.backList}},[_vm._v(" < 返回上一级 ")]),_c('div',{staticClass:"ignore-mt40 ignore-fz24 bold"},[_vm._v(" "+_vm._s(_vm.activeCase.articleTitle)+" ")]),_c('div',{staticClass:"flex flex-align-center ignore-mt14"},[_c('span',{staticClass:"c-999 bold ignore-fz16 ignore-mr20"},[_vm._v(_vm._s(_vm.activeCase.ptime))]),_c('div',{staticClass:"flex flex-align-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.watchPic),expression:"watchPic"}],staticClass:"ignore-watch-icon"}),_c('span',{staticClass:"ignore-fz12 c-999"},[_vm._v(_vm._s(_vm.activeCase.pv))])])]),_c('div',{staticClass:"ignore-grey-line"}),_c('div',{staticClass:"ignore-html ignore-mt30",domProps:{"innerHTML":_vm._s(_vm.activeCase.articleContent)}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-v w100 flex-align-center ignore-case-header"},[_c('div',{staticClass:"ignore-fz32 bold ignore-mt120 z0"},[_vm._v("项目案例")]),_c('div',{staticClass:"ignore-fz16 ignore-mt8 uppercase c-cac z0"},[_vm._v("Our CASES")]),_c('div',{staticClass:"z0 ignore-color-line ignore-mt8"})])}]

export { render, staticRenderFns }