<template>
  <div class="flex-v flex-align-center w100 ignore-case">
    <div class="flex-v w100 flex-align-center ignore-case-header">
      <div class="ignore-fz32 bold ignore-mt120 z0">项目案例</div>
      <div class="ignore-fz16 ignore-mt8 uppercase c-cac z0">Our CASES</div>
      <div class="z0 ignore-color-line ignore-mt8"></div>
    </div>
    <div class="ignore-case-list" v-if="showList">
      <div
        class="ignore-case-item flex flex-align-center"
        v-for="(item, index) in caseList"
        :key="index"
      >
        <router-link
          :to="{
            name: 'caseCenter',
            query: { caseId: item.articleId, name: item.articleTitle },
          }"
          target="_blank"
        >
          <img
            v-lazy="item.coverUrl + item.coverPath + item.articleCover"
            class="ignore-case-icon"
          />
        </router-link>
        <div class="flex-v flex-1 h100">
          <router-link
            :to="{
              name: 'caseCenter',
              query: { caseId: item.articleId, name: item.articleTitle },
            }"
            target="_blank"
            ><div class="ignore-fz24 bold ignore-mt20">
              {{ item.articleTitle }}
            </div>
          </router-link>
          <div class="ignore-fz14 ignore-mt30 max-line3 flex ignore-ml3-fz14">
            {{ item.articleIntro }}
          </div>
          <div class="flex flex-1"></div>
          <div class="flex w100 flex-align-center flex-pack-between">
            <div class="flex flex-align-center">
              <img class="ignore-watch-icon" v-lazy="watchPic" />
              <span class="ignore-fz14 c-999">{{ item.pv }}</span>
            </div>
            <div
              class="relative ignore-more-btn-box"
              @mouseenter="item.active = true"
              @mouseleave="item.active = false"
            >
              <div
                class="animate__animated ignore-more-btn flex flex-align-center flex-pack-center c-39e ignore-fz16 pointer"
                 v-show="item.active !== true"
              >
                更多
              </div>
              <router-link
                :to="{
                  name: 'caseCenter',
                  query: { caseId: item.articleId, name: item.articleTitle  },
                }"
                target="_blank"
              >
                <div
                  class="animate__animated ignore-more-btn-active flex flex-align-center flex-pack-center c-white ignore-fz16 pointer"
                  :class="
                    item.active == true
                      ? 'animate__fadeIn'
                      : item.active == false
                      ? 'animate__fadeOut'
                      : 'more-hidden'
                  "
                >
                  更多
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
     <el-pagination
          background="white"
          layout="prev, pager, next"
          :total="totalRecord"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          class="ignore-mt20 pagination"
          v-if="totalRecord > 8"
        >
        </el-pagination>
    </div>
    <div class="ignore-case-detail" v-else>
      <div class="ignore-fz14 c-39e pointer" @click="backList">
        &lt; 返回上一级
      </div>
      <div class="ignore-mt40 ignore-fz24 bold">
        {{ activeCase.articleTitle }}
      </div>
      <div class="flex flex-align-center ignore-mt14">
        <span class="c-999 bold ignore-fz16 ignore-mr20">{{
          activeCase.ptime
        }}</span>
        <div class="flex flex-align-center">
          <img class="ignore-watch-icon" v-lazy="watchPic" />
          <span class="ignore-fz12 c-999">{{ activeCase.pv }}</span>
        </div>
      </div>
      <div class="ignore-grey-line"></div>
      <div
        v-html="activeCase.articleContent"
        class="ignore-html ignore-mt30"
      ></div>
    </div>
  </div>
</template>

<script>
window.addEventListener("beforeunload", function () {
  window.scrollTo(0, 0);
});
import watchPic from "@/assets/home/watch.png";
import { getHomeCase } from "@/api/home";
import { getCaseDetail } from "@/api/case";

export default {
  name: "caseCenter",
  data() {
    return {
      watchPic,
      caseList: [],
      showList: true,
      activeCase: {},
      totalRecord: 0,
      pageIndex: 1,
      pageSize: 8,
    };
  },
  methods: {
    getCaseList() {
      let param = {
        pageIndex: this.pageIndex,
        // pageSize: this.pageSize,
      };
      getHomeCase(param).then((res) => {
        console.log(res)
        this.totalRecord = res.totalRecord;
        let list = res.objList;
        list.forEach((item) => {
          item.active = -1;
        });
        this.caseList = list;
        if (this.$route.query.caseId) {
          let caseItem = this.caseList.find(
            (item) => item.articleId == this.$route.query.caseId
          );
          this.getDetail(caseItem);
        }
      });
    },
    handleCurrentChange(page) {
      this.pageIndex = page;
      this.getCaseList();
    },
    getDetail(item) {
      getCaseDetail(item.articleId).then((res) => {
        let caseItem = res[0];
        // caseItem.ptime = this.tarnslateDate(caseItem.ptime);
        this.activeCase = caseItem;
      });
      this.showList = false;
      this.initScroll();
    },
    initScroll() {
      window.scrollTo(0, 0);
    },
    backList() {
      this.activeCase = {};
      this.caseList.forEach((item) => {
        item.active = -1;
      });
      this.showList = true;
      this.initScroll();
      this.$router.push({ query: {} });
    },
    tarnslateDate(date) {
      let nowdate = new Date(date).toLocaleDateString();
      nowdate = nowdate.substring(2);
      return nowdate;
    },
  },
  activated() {
    this.caseList.forEach((item) => {
      item.active = -1;
    });
    this.showList = true;
    this.activeCase = {};
    window.scrollTo(0, 0);
    if (this.caseList.length > 0 && this.$route.query.caseId) {
      let caseItem = this.caseList.find(
        (item) => item.articleId == this.$route.query.caseId
      );
      this.getDetail(caseItem);
    }
  },
  mounted() {
    this.getCaseList();
  },
};
</script>

<style lang="scss" scoped>
.ignore-case {
  background: url("../assets/case/bg.jpg") no-repeat;
  background-size: 100% 320px;
  background-color: #f1f1f1;
  min-height: 1000px;
  .ignore-case-list {
    width: 1500px;
    margin-top: 65px;
    margin-bottom: 20px;
    .ignore-case-item {
      width: 1500px;
      height: 330px;
      background: #ffffff;
      border-radius: 6px;
      margin-bottom: 20px;
      padding: 40px 29px 38px 53px;
      .ignore-case-icon {
        width: 420px;
        height: 252px;
        margin-right: 27px;
      }
      .ignore-watch-icon {
        width: 25px;
        height: 18px;
        margin-right: 8px;
      }
    }
  }
  .ignore-case-detail {
    width: 1500px;
    min-height: 420px;
    background: #ffffff;
    border-radius: 6px;
    padding: 30px;
    margin-bottom: 40px;
    margin-top: 68px;
    .ignore-watch-icon {
      width: 20px;
      height: 14px;
      margin-right: 8px;
    }
    .ignore-html >>> img {
      max-width: 100%;
      margin: 0 auto;
    }
    .ignore-grey-line {
      height: 1px;
      width: 100%;
      background: #e2e2e2;
      margin-top: 30px;
    }
    .ignore-html >>> p {
      line-height: 1.5em;
      font-size: 16px;
    }
  }
}
.ignore-case-list .pagination{
  text-align: center;
}
.ignore-color-line {
  width: 50px;
  height: 3px;
  background: linear-gradient(-90deg, #34b5ff, #39e274);
  border-radius: 2px;
}
.ignore-case-header {
  z-index: 2;
  width: 100%;
}

.ignore-more-btn-box {
  width: 150px;
  height: 50px;
  .ignore-more-btn {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: #ffffff;
    border: 1px solid #2a3038;
    color: #475669;
    position: absolute;
    left: 0;
    top: 0;
  }
  .ignore-more-btn-active {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: linear-gradient(
      -45deg,
      rgba(52, 181, 255, 0.83),
      rgba(57, 226, 116, 0.83)
    );
    box-shadow: 0px 18px 25px 0px rgba(57, 226, 116, 0.33);
    border: none;
    position: absolute;
    left: 0;
    top: 0;
  }
  .more-hidden {
    opacity: 0;
  }
}
</style>
<!-- <style scoped>
@media (max-width: 1366px) {
  .ignore-case {
    background-size: 100% 260px !important;
    min-height: 1060px !important;
  }
  .ignore-case-header .ignore-mt120 {
    margin-top: 100px !important;
  }
  .ignore-case-header .ignore-mt30 {
    margin-top: 12px !important;
  }
  .ignore-case-header .ignore-fz32 {
    font-size: 26px !important;
  }
  .ignore-case-list {
    width: 900px !important;
    margin-top: 45px !important;
  }
  .ignore-case-item {
    width: 900px !important;
    height: 185px !important;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 20px 14px 18px 33px !important;
  }
  .ignore-case-item .ignore-mt20 {
    margin-top: 14px !important;
  }
  .ignore-case-item .ignore-fz24 {
    font-size: 18px !important;
  }
  .ignore-case-item .ignore-fz14 {
    font-size: 12px;
  }
  .ignore-more-btn-box {
    width: 120px !important;
    height: 40px !important;
  }
  .ignore-more-btn {
    width: 120px !important;
    height: 40px !important;
  }
  .ignore-more-btn-active {
    width: 120px !important;
    height: 40px !important;
  }
  .ignore-case-item .ignore-fz16 {
    font-size: 14px !important;
  }
  .ignore-case-item .ignore-mt30 {
    margin-top: 10px !important;
  }
  .ignore-case-icon {
    width: 220px !important;
    height: 122px !important;
    margin-right: 17px !important;
  }
  .ignore-watch-icon {
    width: 18px !important;
    height: 12px !important;
    margin-right: 4px !important;
  }
  .ignore-case-detail {
    width: 900px !important;
    padding: 20px !important;
    margin-bottom: 30px !important;
    margin-top: 45px !important;
  }
  .ignore-case-detail .ignore-mt40 {
    margin-top: 26px !important;
  }
  .ignore-case-detail .ignore-fz24 {
    font-size: 20px !important;
  }
  .ignore-case-detail .ignore-mt14 {
    margin-top: 6px !important;
  }
  .ignore-case-detail .ignore-fz16 {
    font-size: 14px !important;
  }
  .ignore-grey-line {
    margin-top: 14px !important;
  }
  .ignore-case-detail .ignore-mt30 {
    margin-top: 20px !important;
  }
  .ignore-html {
    width: 100% !important;
  }
  .ignore-html >>> img {
    max-width: 100% !important;
    margin: 0 auto;
  }
}
</style> -->